@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,600;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html body {
  height: 100%;
  background-color: #d0d6df;
}
#root {
  min-width: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100vh;
}

:root {
  --dark_bg: #1e1e33;
  --dark_outline: #3d3d54;
  --white: #ffffff;
  --accent: #17c1c1;
  --accenthov: #26dac9;
  --error: #d3234f;
  --lightgray: #f2f4f6;
  --gray: #d0d6df;
  --body_color: #6f6f82;
}

/*  COMPONENT */
.component_logoimg {
  object-fit: contain;
}
.container_gridlayout {
  box-shadow: 1px 2px 8px 1px #0000005e;
  background-color: #6f6f82;
  background-color: var(--body_color);
  padding: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  overflow: hidden;
}

.react-grid-layout.landscape {
  overflow: visible;
  background-color: #fff;
  margin: auto;
  width: 1920px;
  height: 1080px !important;
  min-height: 1080px;
  max-height: 1080px;
}


.react-grid-layout.portrait {
  overflow: visible;
  background-color: #fff;
  margin: auto;
  width: 1080px;
  height: 1920px !important;
  min-height: 1920px;
  max-height: 1920px;}
/* Container base*/
.container_component {
  width: 100%;
  height: 100%;
  transition: all 250ms ease;
  display: flex;
}
/* TITLEBAR*/

.icon_container {
  height: 100%;
}
.text_title h1 {
  line-height: 0;
  width: -webkit-max-content;
  width: max-content;
  font-size: inherit;
  align-self: center;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 12px;
}
.text_title {
  overflow: hidden;
}
/*  PROMO CAROUSEL */
.promo {
  border: 1px solid #d6d6d6;
  padding: 5px;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}
.promo .carousel {
  height: 100%;
}

.promo .carousel-inner {
  height: 100%;
}

.promo .carousel-item {
  height: 100%;
}
.promo .promo_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.slide-demo {
  height: 100%;
  background-color: #ffffff;
  display: flex;
  place-items: center;
  justify-content: center;
}

.slide-demo h2 {
  text-align: center;
  color: #2e2e2e;
  font-size: 3em;
}
/* PROGRESS */
body .progress_container {
  display: flex;
  width: 100%;
  height: 120px;
  align-items: center;
}
body .progress {
  display: flex;
  height: 0.7rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid #ababab;
}

body .progress .progress-bar{
  background-color: #ffffff;
}


body .progress.progress-rectangle {
  margin: auto 220px auto 40px;
  width: 100%;
}
.circle-fluidity {
  background-color: #d6d6d6;
  width: 150px;
  height: 150px;
  max-width: 140px;
  padding: 0;
  display: block;
  position: absolute;
  right: 40px;
  border-radius: 100%;
  bottom: -50px;
  box-shadow: 1px 2px 8px 1px #0000005e;
}

/* INFOFLUIDITY */
.listcontainer-infoFluidity {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.number-infoFluidity {
  font-weight: bold;
  font-family: "Montserrat";
  color: #282c2d;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textcontainer-infoFluidity {
  border-bottom: 3px solid #d6d6d6;
  height: 100%;
  align-self: center;
}
.item-infoFluidity:nth-last-child(1)  .textcontainer-infoFluidity{
  border-bottom: none;
}
.item-infoFluidity{
  margin-bottom: 15px;
}
.noresize .react-resizable-handle {
  display: none;
}

.resizeSVG svg {
  width: 100%;
  height: 100%;
}

.resizeSVGImageFoodGroup svg {
  min-height: 100%;
  width: 100%;
  max-height: 400px;
  flex-shrink: 0;
  display: flex;
  height: 0;
  padding: 30px;
  }
.svgTitle {
width: 100%;
height: 100%;
}

.imageCarousel {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.multiFoodGroupList div:last-child {
  border: none;
}
.multiFoodGroupList div  {
  border-bottom: 1px solid #d6d6d6;
}


/* NEW */


.iconWeather svg{
  height: 100%;
  width: auto;
}

.itemCardFg:nth-last-child(1){
 margin-bottom:20px;
}
.itemCardFg{
 margin-bottom:0;
}
.containerCardFg{
    width: calc(100% - 110px);
     /* height: 100%; */
}

.newshadow {
  box-shadow: 0px 4px 7px 0px #1e1e3382;
}



  .imageCarousel {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.video-section{
height: 100%;
    background-color: #282c2d;}

.video-section embed video{  

    width: 100%;
    height: 100%;}

    .textpromo-title{
      display: block;
      width: 100%;
      text-align: center;
    }

    .slide-textpromo{
  display: flex;
    height: 100%;
      width: 100%;
      flex-direction: column;
      text-align: center;
      justify-content: center;
    }
/* @import url('https://fonts.googleapis.com/css2?family=Langar&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sniglet&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400;700&display=swap'); 

@font-face {
  font-family: 'Helvetica Neue';
  src:  url('./fonts/HelveticaNeueMedium.ttf') format('truetype'),
      url('./fonts/HelveticaNeueBold.ttf') format('truetype'),
      url('./fonts/HelveticaNeueLight.ttf') format('truetype')
    }*/

/* HELVETICA NEUE */
@font-face {
  font-family: 'Helvetica Neue Regular';
  src:  url(/static/media/HelveticaNeueMedium.b4dad7d2.ttf) format('truetype');
  font-weight: normal;
    }

    @font-face {
    font-family: 'Helvetica Neue Bold';
    src: url(/static/media/HelveticaNeueBold.caac1e27.ttf) format('truetype');
    font-weight: bold;
    }
    @font-face {
    font-family: 'Helvetica Neue Light';
   src: url(/static/media/HelveticaNeueLight.f1279b7b.ttf) format('truetype');
      font-weight: lighter;
    }



/* Caveat */
@font-face {
  font-family: 'Caveat Regular';
  src:  url(/static/media/Caveat-Medium.7d8013d1.ttf) format('truetype');
   font-weight: normal;
    }

    @font-face {
  font-family: 'Caveat Bold';
  src:  url(/static/media/Caveat-Bold.4c36c657.ttf) format('truetype');
      font-weight: bold;
    }

    @font-face {
      font-family: 'Caveat Light';
      src:  url(/static/media/Caveat-Regular.ff4921b1.ttf) format('truetype');
      font-weight: lighter;
    }


/* DMSerifText */
@font-face {
  font-family: 'DMSerifText Regular';
  src:  url(/static/media/DMSerifText-Regular.006d6455.ttf) format('truetype');
    font-weight: normal;
    }


    
/* Dosis */
@font-face {
  font-family: 'Dosis Regular';
  src:  url(/static/media/Dosis-Medium.fea35723.ttf) format('truetype');
      font-weight: normal;
    }

    @font-face {
  font-family: 'Dosis Bold';
    src:  url(/static/media/Dosis-Bold.2cd4d04a.ttf) format('truetype');
      font-weight: bold;
    }

    @font-face {
   font-family: 'Dosis Light';
      src:  url(/static/media/Dosis-Light.767ad798.ttf) format('truetype');
      font-weight: lighter;
    }

    
/* Montserrat */
@font-face {
  font-family: 'Montserrat Regular';
  src:  url(/static/media/Montserrat-Medium.e2d60bc4.ttf) format('truetype');
      font-weight: normal;
    }

    @font-face {
  font-family: 'Montserrat Bold';
    src:  url(/static/media/Montserrat-Bold.079ca05d.ttf) format('truetype');
      font-weight: bold;
    }

    @font-face {
   font-family: 'Montserrat Light';
      src:  url(/static/media/Montserrat-Light.02afb26f.ttf) format('truetype');
      font-weight: lighter;
    }


/* MarkScript */
@font-face {
  font-family: 'MarkScript';
  src:  url(/static/media/MarckScript-Regular.71c2b438.ttf) format('truetype');
      font-weight: normal;
    }

   



/* MPLUSRounded1c */
@font-face {
  font-family: 'MPLUSRounded1c Regular';
  src:  url(/static/media/MPLUSRounded1c-Medium.69141f53.ttf) format('truetype');
      font-weight: normal;
    }

    @font-face {
  font-family: 'MPLUSRounded1c Bold';
    src:  url(/static/media/MPLUSRounded1c-Bold.5dd5b479.ttf) format('truetype');
      font-weight: bold;
    }

    @font-face {
   font-family: 'MPLUSRounded1c Light';
      src:  url(/static/media/MPLUSRounded1c-Light.bae1cd78.ttf) format('truetype');
      font-weight: lighter;
    }


/* OpenSans-Regular */
@font-face {
  font-family: 'OpenSans Regular';
  src:  url(/static/media/OpenSans-Regular.1b0809d5.ttf) format('truetype');
      font-weight: normal;
    }

    @font-face {
  font-family: 'OpenSans Bold';
    src:  url(/static/media/OpenSans-Bold.dbb97fd9.ttf) format('truetype');
      font-weight: bold;
    }

    @font-face {
   font-family: 'OpenSans Light';
      src:  url(/static/media/OpenSans-Light.ed41bedf.ttf) format('truetype');
      font-weight: lighter;
    }
/* PermanentMarker */
@font-face {
  font-family: 'PermanentMarker';
  src:  url(/static/media/PermanentMarker-Regular.109a10dc.ttf) format('truetype');
      font-weight: normal;
    }

/* Roboto  */
@font-face {
  font-family: 'Roboto Regular';
  src:  url(/static/media/Roboto-Medium.13a29228.ttf) format('truetype');
      font-weight: normal;
    }

    @font-face {
  font-family: 'Roboto Bold';
    src:  url(/static/media/Roboto-Bold.4f39c579.ttf) format('truetype');
      font-weight: bold;
    }

    @font-face {
   font-family: 'Roboto Light';
      src:  url(/static/media/Roboto-Light.0cea3982.ttf) format('truetype');
      font-weight: lighter;
    }
/* Roboto Condensed  */
@font-face {
  font-family: 'RobotoCondensed Regular';
  src:  url(/static/media/RobotoCondensed-Regular.db6d9169.ttf) format('truetype');
      font-weight: normal;
    }

    @font-face {
  font-family: 'RobotoCondensed Bold';
    src:  url(/static/media/RobotoCondensed-Bold.89a39652.ttf) format('truetype');
      font-weight: bold;
    }

    @font-face {
   font-family: 'RobotoCondensed Light';
      src:  url(/static/media/RobotoCondensed-Light.877e34f9.ttf) format('truetype');
      font-weight: lighter;
    }

/* Arial */

      @font-face {
    font-family: 'Arial';
    src:  url(/static/media/ARIAL.5afb1f7c.TTF) format('truetype');
      font-weight: normal;
    }

       @font-face {
    font-family: 'Arial Bold';
    src:  url(/static/media/ARIALBD.64dde1b4.TTF) format('truetype');
       font-weight: bold;
    }
       @font-face {
    font-family: 'Arial Light';
    src:  url(/static/media/ARIALLGT.12468abe.TTF) format('truetype');
      font-weight: lighter;
    }


    /* Kalam */
      @font-face {
    font-family: 'Kalam Regular';
    src:  url(/static/media/Kalam-Regular.ca4a04f6.ttf) format('truetype');
      font-weight: normal;
    }

       @font-face {
    font-family: 'Kalam Bold';
    src:  url(/static/media/Kalam-Bold.d81b8c41.ttf) format('truetype');
       font-weight: bold;
    }
       @font-face {
    font-family: 'Kalam Light';
    src:  url(/static/media/Kalam-Light.aa07caf9.ttf) format('truetype');
      font-weight: lighter;
    }


       /* Avenir */
       @font-face {
        font-family: 'Avenir Regular';
        src:  url(/static/media/AvenirNextCyr-Medium.179fa5da.ttf) format('truetype');
          font-weight: normal;
        }
    
           @font-face {
        font-family: 'Avenir Bold';
        src:  url(/static/media/AvenirNextCyr-Bold.d7a7f3cc.ttf) format('truetype');
           font-weight: bold;
        }
           @font-face {
        font-family: 'Avenir Light';
        src:  url(/static/media/AvenirNextCyr-Regular.d5515aff.ttf) format('truetype');
          font-weight: lighter;
        }

       /* CoveredByYourGrace */
      @font-face {
    font-family: 'CoveredByYourGrace Regular';
    src:  url(/static/media/CoveredByYourGrace-Regular.66fbc77f.ttf) format('truetype');
      font-weight: normal;
    }



   /*   FONT FROM ALDEBARAN WEB FO THE PROMO*/
   @font-face {
    font-family: "Open Sans";
    src: url(/static/media/OpenSans-Bold.dbb97fd9.ttf) format("truetype")
}
@font-face {
    font-family: "Open Sans";
    src: url(/static/media/OpenSans-Light.ed41bedf.ttf) format("truetype")
}

@font-face {
    font-family: "Avenir";
    src: url(/static/media/AvenirNextCyr-Bold.d7a7f3cc.ttf) format("truetype")
}
@font-face {
    font-family: "Avenir";
    src: url(/static/media/AvenirNextCyr-Regular.d5515aff.ttf) format("truetype")
}

@font-face {
    font-family: "Helvetica Neue";
    src: url(/static/media/HelveticaNeueBold.caac1e27.ttf) format("truetype")
}
@font-face {
    font-family: "Helvetica Neue";
    src: url(/static/media/HelveticaNeue-Light.695e35a2.otf) format("truetype")
}

@font-face {
    font-family: "Montserrat";
    src: url(/static/media/Montserrat-ExtraBold.1944c8d1.ttf) format("truetype")
}
@font-face {
    font-family: "Montserrat";
    src: url(/static/media/Montserrat-Regular.3cd78665.ttf) format("truetype")
}

@font-face {
    font-family: "Roboto";
    src: url(/static/media/Roboto-Black.1224b64c.ttf) format("truetype")
}
@font-face {
    font-family: "Roboto";
    src: url(/static/media/Roboto-Medium.13a29228.ttf) format("truetype")
}
@font-face {
    font-family: "Roboto";
    src: url(/static/media/Roboto-Light.0cea3982.ttf) format("truetype")
}
